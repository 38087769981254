<template>
    <div class="index">
        <!-- 头部 -->
        <div class="header_wrap">
            <div class="content">
                <div class="nav_wrap">
                    <div class="left">
                        <div class="logo">
                            <img src="../../assets/common/logo1.png" alt="">
                        </div>
                        <router-link to="/index" class="nav_list">网站首页</router-link>
                        <router-link to="/introduction" class="nav_list">产品简介</router-link>
                        <router-link to="/recruitment" class="nav_list">诚聘英才</router-link>
                        <router-link to="/about" class="nav_list">关于我们</router-link>
                        <router-link to="/app_download" class="nav_list">APP下载</router-link>
                    </div>
                    <!-- <div class="right">
                        <button>登录</button>
                        <button>注册</button>
                    </div> -->
                </div>
                <div class="center_wrap">
                    <div class="left">
                        <div class="title1">欣享云智能科技</div>
                        <div class="title2">
                            <span>轻松扫描</span>
                            <span>快速打印</span>
                        </div>
                        <div class="title3">集智能设备及平台软件开发、投资、运营为一体的高科技公司，拥有自主知识产权的共享自助打印照相终端设备、云打印平台。</div>
                        <div class="btn_wrap">
                            <div class="btn btn1" @click="goPage('/introduction')">
                                <div class="icon">
                                    <img src="~@/assets/common/sanjiao.png" alt="">
                                </div>
                                <div class="text">立即查看 ></div>
                            </div>
                            <div class="btn btn2" @click="goPage('/app_download')">
                                <div class="icon">
                                    <img src="~@/assets/common/qrcode.png" alt="">
                                </div>
                                <div class="text">APP下载 ></div>
                            </div>
                        </div>
                    </div>
                    <div class="right">
                        <img src="~@/assets/common/phone.png" alt="">
                    </div>
                </div>
            </div>
        </div>
        <!-- 关于 -->
        <div class="about_wrap">
            <div class="content">
                <div class="left">
                    <div class="img">
                        <img src="~@/assets/common/img1.png" alt="">
                    </div>
                    <div class="btn">
                        <button><router-link to="/about" class="nav_list" style='color:#fff;'>查看更多 &gt;</router-link> </button>
                    </div>
                </div>
                <div class="center">
                    <div class="img">
                        <img src="~@/assets/common/img2.png" alt="">
                    </div>
                    <div class="img">
                        <img src="~@/assets/common/img3.png" alt="">
                    </div>
                </div>
                <div class="right">
                    <div class="title">关于我们</div>
                    <div class="desc1">
                        我们的经营理念是<br/>“诚信、创新、分享、多赢”
                    </div>
                    <div class="desc2">
                        　　贵州欣享云智能科技有限公司成立于2020年9月，总部位于贵阳市观山湖区，是一家集智能设备及平台软件开发、投资、运营为一体的高科技公司。目前，我公司拥有自主知识产权的共享自助打印照相终端设备、云打印平台已研发完成并上线，并且还在研发新型设备，不断改良我们的产品，以给用户带来更好的体验。……
                    </div>
                    <div class="tab_wrap">
                        <div class="list">
                            <div class="icon">
                                <img src="~@/assets/common/tab1.png" alt="">
                            </div>
                            <div class="text">全国各院校</div>
                        </div>
                        <div class="list">
                            <div class="icon">
                                <img src="~@/assets/common/tab2.png" alt="">
                            </div>
                            <div class="text">风景名胜地</div>
                        </div>
                        <div class="list">
                            <div class="icon">
                                <img src="~@/assets/common/tab3.png" alt="">
                            </div>
                            <div class="text">机场/高铁/汽车站</div>
                        </div>
                        <div class="list">
                            <div class="icon">
                                <img src="~@/assets/common/tab4.png" alt="">
                            </div>
                            <div class="text">各服务大厅</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 业务范围 -->
        <div class="profession_wrap">
            <div class="content">
                <div class="title">业务范围</div>
                <div class="desc">
                    共享自助打印照相终端机设备，赋予传统照相打字业务新的生命力，方便群众的同时，带领合作商共同发展。
                </div>
                <div class="list_wrap">
                    <div class="list" v-for="(item,index) in professionData" :key="index">
                        <div class="lw_icon">
                            <img :src="item.icon" alt="">
                        </div>
                        <div class="lw_title">
                            {{item.title}}
                        </div>
                        <div class="lw_desc">
                            {{item.desc}}
                        </div>
                        <div class="lw_border">
                            <div class="bor bor1"></div>
                            <div class="bor bor2"></div>
                        </div>
                    </div>
                </div>
                <div class="detail_desc">
                    <div class="dd_wrap">
                        <div class="dd_img">
                            <img src="~@/assets/common/K1.png" alt="">
                        </div>
                        <div class="dd_content">
                            <div class="dd_icon">
                                <img src="~@/assets/common/dd1.png" alt="">
                            </div>
                            <div class="dd_title">
                                <span>享受即时打印</span>
                                <span>留下美好瞬间</span>
                            </div>
                            <div class="dd_desc1">自主知识产权的共享自助打印照相终端设备、云打印平台已研发完成并上线，并且还在研发新型设备，不断改良我们的产品，以给用户带来更好的体验……</div>
                            <div class="dd_desc2">
                                <div class="desc2_list">
                                    <div class="dl_icon">
                                        <img src="~@/assets/common/gou.png" alt="">
                                    </div>
                                    <div class="dl_text">自主知识产权的共享自助打印照相终端设备</div>
                                </div>
                                <div class="desc2_list">
                                    <div class="dl_icon">
                                        <img src="~@/assets/common/gou.png" alt="">
                                    </div>
                                    <div class="dl_text">云打印平台已研发完成并上线</div>
                                </div>
                                <div class="desc2_list">
                                    <div class="dl_icon">
                                        <img src="~@/assets/common/gou.png" alt="">
                                    </div>
                                    <div class="dl_text">研发新型设备，不断改良我们的产品</div>
                                </div>
                            </div>
                            <div class="dd_border">
                                <div class="bor bor1"></div>
                                <div class="bor bor2"></div>
                            </div>
                        </div>
                    </div>
                    <div class="dd_wrap">
                        <div class="dd_content">
                            <div class="dd_icon">
                                <img src="~@/assets/common/dd2.png" alt="">
                            </div>
                            <div class="dd_title">
                                <span>共享智能科技</span>
                                <span>印出美好生活</span>
                            </div>
                            <div class="dd_desc1">贵州欣享云智能科技有限公司成立于2020年9月，总部位于贵阳市观山湖区，是一家集智能设备及平台软件开发、投资、运营为一体的高科技公司。目前，我公司拥有自主知识产权的共享自助打印照相终端设备、云打印平台已研发完成并上线，并且还在研发新型设备，不断改良我们的产品，以给用户带来更好的体验。……</div>
                            <div class="dd_border">
                                <div class="bor bor1"></div>
                                <div class="bor bor2"></div>
                            </div>
                        </div>
                        <div class="dd_img">
                            <img src="~@/assets/common/K2.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 底部 -->
        <Footer></Footer>
    </div>
</template>
<script>
    import Footer from '@/components/Footer.vue'
    export default {
        data(){
            return{
                professionData:[
                    {
                        icon:require('../../assets/common/profession1.png'),
                        title:'文档打印',
                        desc:'支持Word/Excel/PPT/PDF等文件，自助打印，方便快捷。'
                    },
                    {
                        icon:require('../../assets/common/profession2.png'),
                        title:'照片打印',
                        desc:'拍照/相册/聊天记录，共享自助打印，方便快捷。'
                    },
                    {
                        icon:require('../../assets/common/profession3.png'),
                        title:'证件照复印',
                        desc:'制作证件照片打印/复印，共享自助打印，方便快捷。'
                    },
                    {
                        icon:require('../../assets/common/profession4.png'),
                        title:'照片冲印',
                        desc:'证件照冲印/普通照片冲印，自助打印，方便快捷。'
                    },
                    {
                        icon:require('../../assets/common/profession5.png'),
                        title:'即刻拍照',
                        desc:'立即拍照/立即打印，同时制作修改，方便快捷。'
                    },
                    {
                        icon:require('../../assets/common/profession6.png'),
                        title:'照片制作',
                        desc:'制作证件照片，修改照片，并打印，方便快捷。'
                    },
                ]
            }
        },
        components:{
            Footer
        },
        methods:{
            goPage(url){
                this.$router.push(url)
            }
        }
    }
</script>

<style lang="less">
    
    .index{
        .header_wrap{
            background: url('../../assets/common/index_bg.png');
            background-size:cover;
            background-repeat:no-repeat;
            .content{
                width: @max_width;
                margin: 0 auto;
                .nav_wrap{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 86px;
                    .left{
                        display: flex;
                        align-items: center;
                        .nav_list{
                            margin-left: 30px;
                            color: #fff;
                            transition: all 0.3s ease;
                            cursor: pointer;
                            &:hover{
                                transition: all 0.3s ease;
                                color: #01B671;
                            }
                        }
                    }
                    .right{
                        display: flex;
                        padding: 20px 0;
                        button{
                            .btn_style();
                        }
                    }
                }
                .center_wrap{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 150px 0;
                    .left{
                        color: #fff;
                        width: 620px;
                        .title1{
                            font-size:62px;
                            font-weight: bold;
                            letter-spacing: 5px;
                        }
                        .title2{
                            font-size: 49px;
                            font-weight: bold;
                            padding: 20px 0;
                            span:last-child{
                                padding: 40px;
                            }
                        }
                        .title3{
                            font-size: 16px;
                            line-height: 30px;
                        }
                        .btn_wrap{
                            display: flex;
                            padding-top: 30px;
                            .btn{
                                width: 197px;
                                height: 66px;
                                border-radius: 66px;
                                box-sizing: border-box;
                                margin-right: 15px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                cursor: pointer;
                                .text{
                                    padding-left: 15px;
                                    font-size: 20px;
                                }
                            }
                            .btn1{
                                background:#fff;
                                color: #01B671;
                            }
                            .btn2{
                                border: 1px solid #ddd;
                            }
                        }
                    }
                }
            }
        }
        .about_wrap{
            background:#fff;
            padding: 80px 0;
            .content{
                width: @max_width;
                margin: 0 auto;
                display: flex;
                align-items: center;
                justify-content: center;
                .left{
                    .btn{
                        text-align: right;
                        button{
                            .btn_style();
                            display: inline-block;
                            margin-right: 10px;
                        }
                    }
                }
                .right{
                    width: 474px;
                    margin-left: 40px;
                    .title{
                        font-size: 20px;
                        color: #01B671;
                    }
                    .desc1{
                        font-size: 24px;
                        font-weight: bold;
                        padding: 20px;
                    }
                    .desc2{
                        text-indent: 2em;
                        line-height: 25px;
                        text-align: justify;
                        color: #8492A6;
                    }
                    .tab_wrap{
                        display: flex;
                        flex-wrap:wrap;
                        justify-content: space-between;
                        padding-top: 10px;
                        .list{
                            display: flex;
                            align-items: center;
                            width: 190px;
                            padding: 15px;
                            border: 1px solid #F6F6F6;
                            margin-top: 10px;
                            cursor: pointer;
                            .text{
                                padding-left: 15px;
                            }
                        }
                    }
                }
            }
        }
        .profession_wrap{
            background:#F9FAFC;
            padding: 70px 0;
            .content{
                width: @max_width;
                margin: 0 auto;
                .title{
                    text-align: center;
                    font-size: 24px;
                    color: #384555;
                }
                .desc{
                    width:552px;
                    margin: 20px auto;
                    text-align: center;
                    color: #8492A6;
                    line-height: 25px;
                }
                .list_wrap{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    .list{
                        height: 200px;
                        width: 28%;
                        background:#fff;
                        margin-top: 10px;
                        border-radius: 8px;
                        border: 2px solid #F6F6F6;
                        padding: 45px 2%;
                        .lw_title{
                            font-size: 20px;
                            font-weight: bold;
                            padding-top: 20px;
                        }
                        .lw_desc{
                            color: #8492A6;
                            line-height: 25px;
                            padding-top: 15px;
                        }
                        .lw_border{
                            padding-top: 15px;
                            display: flex;
                            .bor{
                                width: 54px;
                                height: 5px;
                                background:#01B671;
                            }
                            .bor2{
                                margin-left: 5px;
                                opacity: 0.3;
                            }
                        }
                    }
                }
                .detail_desc{
                    .dd_wrap{
                        padding-top: 100px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .dd_content{
                            padding-left: 40px;
                            width: 40%;
                            .dd_title{
                                font-size: 24px;
                                color: #384555;
                                padding: 20px 0;
                                span:last-child{
                                    padding-left: 40px;
                                }
                            }
                            .dd_desc1{
                                color: #8492A6;
                                line-height: 25px;
                                width: 474px;
                                text-align: justify;
                                text-indent: 2em;
                            }
                            .dd_desc2{
                                padding: 10px 0;
                                .desc2_list{
                                    display: flex;
                                    align-items: center;
                                    color: #8492A6;
                                    padding-top: 5px;
                                    .dl_text{
                                        padding-left: 10px;
                                    }
                                }
                            }
                            .dd_border{
                                padding-top: 15px;
                                display: flex;
                                .bor{
                                    width: 54px;
                                    height: 5px;
                                    background:#01B671;
                                }
                                .bor2{
                                    margin-left: 5px;
                                    opacity: 0.3;
                                }
                            }
                        }
                    }
                }
            }
        }
        
    }
</style>